.Blog{
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    padding-bottom: 10rem;
}



.blog-background{
    height: 100%;
    width: 100%;
    position: absolute;
    inset: 0;
    background: url('../../assets/blog-bg.png');
    
    background-size: 100%;
    background-repeat: repeat-y;

    filter: hue-rotate(100deg) brightness(50%) contrast(250%);
    z-index: -2;
}


.blog-background-blur{
    height: 100%;
    width: 100%;
    position: absolute;
    inset: 0;
    backdrop-filter: blur(20px);
    
    background: rgba(0,0,0,0.4);
    background-size: cover;
    z-index: -1;
    filter:  blur(1px);
}



.TitleBox{
    position: relative;
    height: 13vh;
    background: linear-gradient( 90deg, hsl(120, 20%, 50%), hsl(200, 70%, 40%));
    
    clip-path: polygon(14% 0%, 86% 0%, 91% 25%, 91% 75%, 86% 100%, 14% 100%, 9% 75%, 9% 25%);
    
    width: 50vw;
    text-align: center;

    transition: filter 0.3s;
    animation: none;
    cursor: pointer;

    display: grid;

    align-items: center;
    align-content: center;
    
    filter: hue-rotate(80deg) invert(1) contrast(170%) brightness(80%);
}

.TitleBox::after{
    clip-path: polygon(14% 0%, 86% 0%, 91% 25%, 91% 75%, 86% 100%, 14% 100%, 9% 75%, 9% 25%);
    content: '';
    position: absolute;
    background: linear-gradient(#bdc1d0, #79ecec);
    width: 99%;
    height: 95%;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    z-index: 2


}
.TitleBox:hover{
    filter: invert(1);
    animation: text-glow 3s infinite linear;
    
}

@keyframes text-glow{
    0%{
        text-shadow: none;
    }
    50%{
        text-shadow: 0 0 7px rgb(62, 204, 19);
    }
    100%{
        text-shadow: none;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Iceberg&family=Space+Grotesk:wght@600&family=Space+Mono:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@700&family=Iceberg&family=Space+Grotesk:wght@600&family=Space+Mono:wght@700&display=swap');

.TitleBox h2{
    
    
    position: absolute;
    
    
    color: hsl(0, 14%, 22%);
    
    /* mix-blend-mode:difference; */
    margin: 0 ;
    font-size: clamp(0.8rem, 1.8vw, 1.5rem);
    font-family: 'Chakra Petch', sans-serif;
    text-shadow: -1px -1px 0.01rem rgb(199, 199, 199), 1px -1px 0.01rem rgb(199, 199, 199), -1px 1px 0.01rem rgb(199, 199, 199), 1px 1px 0.01rem rgb(199, 199, 199);
    z-index: 3;

    

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
    letter-spacing: 0.02rem;
    
}





.blog-mesh-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    background: rgb(44, 0, 97)  ;
    
    -webkit-mask: url('../../assets/hexagon-29137.svg');
    mask: url('../../assets/hexagon-29137.svg');
    
    opacity: 0;
    -webkit-mask-size: 40%;
    mask-size: 40%;
    -webkit-mask-repeat:repeat;
    mask-repeat:repeat;
    z-index: 3;
    /* filter: invert(1); */
    
    animation: none;

    -webkit-mask-position: 0 0;
        mask-position: 0 0;
   
}


.TitleBox:hover .blog-mesh-overlay{
    opacity: 0.8;
    animation: flow 50s infinite linear;
}

@keyframes flow{
    0%{
        -webkit-mask-position: 0 0;
        mask-position: 0 0;
    }
    100%{
        -webkit-mask-position: 0 2000%;
        mask-position: 0 2000%;
    }
}






.blog-sections-grid-container{
    gap: 0.8rem;
    display: grid;
    justify-items: center;
    align-items: center;

}

.blog-titles-grid-container{
    display: grid;
    gap: 1rem;
}

.section-title{
    text-align: center;
    color: rgb(230, 230, 230);
    font-size: clamp(1.5rem, 1.8vw, 5rem);
    margin: 5rem 0;
    font-family: 'Space Mono', sans-serif;
}

@media(max-width: 1100px){
    .TitleBox{
        width: 75vw;
    }
    
}

@media(max-width: 600px){
    
    .TitleBox{
        width: 96vw;
       
    }
    
}




.blog-intro{
    padding: 3rem 0;
    border-radius: 0.4rem;
    font-family: 'Space Grotesk', sans-serif;
    width: 100%;
    margin: 0 auto 2rem auto;
    background: rgba(0,0,0,0.6);
    text-align: center;
    font-size: clamp(0.9rem, 1.35vw, 3rem);
    
}

.blog-intro h2,
.blog-intro h4,
.blog-intro p{
    margin: 1rem auto;
   width: 70%;
    color: rgb(230, 230, 230);
}







.BlogPage{
    
    background: hsl(254, 30%, 9%);
    padding: 1.5rem 0;
    min-height: 100svh;
    transition: filter 0.5s;
    color: hsl(59, 25%, 80%);
    font-family: 'Space Grotesk', sans-serif;
}

.BlogPage p{
    font-size: clamp(1rem, 1.65vw, 5rem);
    width: 70%;
    margin: 3rem auto;
    line-height: 140%;
}

.BlogPage h1{
    margin: 0;
    text-align: center;
    font-size: clamp(1.3rem, 2.4vw, 7rem)
}




.BlogPage .splitter-hr{
    width: 70%;
    margin: 1rem auto;
    border-color: rgb(190, 190, 190);

}

.BlogPage .key-container{
    
    margin: 1.5rem 0;
    
    
  
    text-align: center;
  font-size: clamp(0.8rem, 1vw, 3rem);
}

.BlogPage .key-container div{
    margin: 0 auto;
    width: 80%;
    color: hsl(108, 67%, 33%);
    border-radius: 0.2rem;
    text-align: start;
    /* display: inline-block; */
    /* background: black;
    box-shadow:  0 0 1rem 1rem black; */
    border: 5px double   rgb(190, 190, 190);
    border-left: none;
    border-right: none;
    padding: 0 0.5rem 1rem 0.5rem;
}

.BlogPage .key-container h4{
    
    
    display: grid;
    grid-template-columns: 0.1fr 0.4fr;

}

.blog-return-btn{
    margin: 0rem auto;
    display: block;
    border-radius: 0.5rem;
    background: hsl(59, 40%, 42%);
    color: hsl(0, 0%, 13%);
    font-family: 'Iceberg', cursive;
    font-size: clamp(1rem, 1vw, 10rem);
    padding: 0.35rem;
    cursor: pointer;
}
.back-nav-container{
    text-align: center;
    
}
.back-nav-container a {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
}
.blog-page-color-mode-btn{

    height: 2.5rem;
    width: 10rem;
    font-size: clamp(1.1rem, 1.1vw, 1.6rem);
    display:block;
    margin: 0 auto 1rem auto;
    border-radius: 0.5rem;
    background: hsl(59, 40%, 42%);
    color: hsl(0, 0%, 13%);
    font-family: 'Iceberg', cursive;



}




@media(max-width:850px){
    .blog-intro h2,
    .blog-intro h4,
    .blog-intro p{
        
    width: 90%;
    }

    .BlogPage .splitter-hr,
    .BlogPage p{
        width: 85%;
        
    }

    .BlogPage .key-container h4{
        
        display: grid;
        grid-template-columns: 0.3fr 0.8fr;

    }


}

/*callback images START*/
.callback-image-container1{
    overflow: hidden;
}
.callback-image1{
    background: url('../../assets/image1CROPPEDandInverted.png');
    width: 100%;
    height: 65vh;
    height: 65svh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.callback-image-container2{
    overflow: hidden;
}
.callback-image2{
    background: url('../../assets/image2CROPPEDandInvered.png');
    margin: 1.5rem 0;
    width: 100%;
    height: 55vh;
    height: 55svh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
/*callback images END*/


.prototype-chain-second-image-container{
    overflow: hidden;
}
.prototype-chain-second-image{
    background: url('../../assets/sasa2EDITEDandINVERTED.png');
    width: 100%;
    height: 75vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}



.modules-image-container{
    overflow: hidden;
}
.modules-image{
    background: url('../../assets/ModulesCroppedAndInverted.png');
    width: 100%;
    height: 40vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
@media(max-width: 1200px){
    .modules-image-container,
    .prototype-chain-second-image-container,
    .callback-image-container1,
    .callback-image-container2{
        overflow-x: scroll;
       
       
        
    }

    .callback-image1,
    .callback-image2,
    .prototype-chain-second-image,
    .modules-image{
        width: 150%;
       
    }

    
}

@media(max-width: 700px){
    
    .callback-image1,
    .callback-image2{
        height: 35vh
    }

    .callback-image1,
    .callback-image2,
    .prototype-chain-second-image,
    .modules-image{
        width: 250%;
    }
}


.lightMode{
    filter: invert(1)
}
