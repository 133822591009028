.Footer{
    position: absolute;
    width: 100%;
    
    bottom: 0;
    left: 0;
    text-align: center;
    font-size: clamp(0.4rem, 0.7vw, 2rem);
    color: rgb(95, 95, 95);
    z-index: 50000
    
}

.Footer h3{
    margin: 0;
}