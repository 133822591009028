@import url('https://fonts.googleapis.com/css2?family=Bungee&family=Cutive&family=Farsan&family=Iceberg&family=Monoton&family=Montserrat+Subrayada:wght@700&family=Noto+Serif+Devanagari:wght@500&family=Oswald:wght@500&family=PT+Sans+Narrow&family=Pacifico&family=Sriracha&family=Staatliches&family=Trocchi&display=swap');

.Home{
  overflow: hidden;
  height: 100vh;
 height: 100svh;
  width: 100%;
  background: none;
  display: grid;

  box-shadow: inset 0rem -5.8rem 10.5rem -10.5rem hsl(0, 71%, 40%), inset 0rem 9rem 10.5rem -10.5rem hsl(0, 71%, 40%);
  
}
// .LOADING{
//   font-size: 150%;
//   position: fixed;
//   height: 110vh;
//   height: 110svh;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   color: white;
//   animation-name: appear3;
//   animation-duration: 2s;
//   animation-fill-mode: forwards;
//   z-index: 1000
// }


// @keyframes appear3{
//   0%{
//     opacity: 1
//   }
//   80%{
//     opacity: 1;
//     display: unset
//   }
//   100%{
//     opacity: 0;
    
//   }
// }
// .closed-cover{
  
//   margin: 0 auto;
//   height: 100vh;
//   width: 100%;
//   background: none;
  
//   background: transparent;
//   box-shadow: inset -51vw 0rem 0rem 0rem black, inset 51vw 0rem 0rem 0rem black;
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// z-index: 1;
// }

// .open-cover-1{
//   pointer-events: none;
//   transition: all 1s;
//   box-shadow: inset 0 0 0rem 0 black, inset 0 0 0rem 0 black;
// }

// .open-cover-2{
//   pointer-events:all;
//   box-shadow: inset -51vw 0rem 0rem 0rem black, inset 51vw 0rem 0rem 0rem black;
// }


.pageSelectorContainer{
  margin-left: 1%;
  align-self: self-start;
  margin-top: 2vh;
  display: grid;
  

  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  justify-items: center;
  align-items: center;
  align-items: center;
}

.intro-container{
  
  
  
  display: grid;
  font-family: 'Bungee', cursive;
  grid-template-rows: 0.3fr 0.3fr ;
  position: relative;
  
  text-align: center;

  font-size: clamp(2rem, 4vw, 4rem);

 
  
  

  

}

.intro-container h1{
  margin: 0;
  position: relative;
  // bottom: -100%;
  
  
}

// .intro-container h1::after{
//   content: "TENEV";
//   color: transparent
// }
.intro-container h1 span{
  position: relative;
  color: transparent;
  pointer-events: none;
  
  
  
}
.intro-container h1 span::after{
  text-shadow: -1px -1px 0px rgb(255, 255, 255), 1px -1px 0px rgb(255, 255, 255), -1px 1px 0px rgb(255, 255, 255), 1px 1px 0px rgb(255, 255, 255);
  
  position: absolute;
  color: rgb(211, 211, 211);
  mix-blend-mode: difference;
  opacity:0;
  // top: -100%;
  // left: var(--left);
  left: 0;
  animation-name: appear;
  animation-delay: var(--delay);
  animation-fill-mode: forwards;
  
  animation-duration: 1.6s;
  // animation-iteration-count: infinite;
}

.intro-container h1 span:nth-child(1):after{
  content: 'T'
}
.intro-container h1 span:nth-child(2):after{
  content: 'E'
}
.intro-container h1 span:nth-child(3):after{
  content: 'N'
}
.intro-container h1 span:nth-child(4):after{
  content: 'E'
}
.intro-container h1 span:nth-child(5):after{
  content: 'V'
}

@for $i from 0 to 5 {
  
  .intro-container h1 span:nth-child(#{$i + 1}):after{
    --delay: #{$i * 0.2}s;
    --hue: #{360 / 5 * $i};
    --left: #{$i  * 1.1}rem;
  }
}

.intro-container h2{
  
  margin: 0;
  position: relative;
  // bottom: -100%;
  
  
  
}

// .intro-container h2::after{
//   content: "TENEV";
//   color: transparent
// }
.intro-container h2 span{
  position: relative;
  color: transparent;
}

.intro-container h2 span::after{
  text-shadow: -1px -1px 0px rgb(255, 255, 255), 1px -1px 0px rgb(255, 255, 255), -1px 1px 0px rgb(255, 255, 255), 1px 1px 0px rgb(255, 255, 255);
  
  position: absolute;
  color: hsl(159, 49%, 19%);
  // top: -100%;
  // left: var(--left);
  left: 0;
  animation-name: appear2;
  // animation-delay: none;
  animation-fill-mode: forwards;
  
  animation-duration: 2.8s;
  // animation-iteration-count: infinite;
  mix-blend-mode: difference;
  filter: blur(100px);
}

.intro-container h2 span:nth-child(1):after{
  content: 'W'
}
.intro-container h2 span:nth-child(2):after{
  content: 'E'
}
.intro-container h2 span:nth-child(3):after{
  content: 'B'
}
.intro-container h2 span:nth-child(4):after{
  content: '-'
}
.intro-container h2 span:nth-child(5):after{
  content: 'D'
}
.intro-container h2 span:nth-child(6):after{
  content: 'E'
}
.intro-container h2 span:nth-child(7):after{
  content: 'V'
}

//loop for separating the letters in web-dev, like in the tenev above
// @for $i from 0 to 7 {
  
//   .intro-container h2 span:nth-child(#{$i + 1}):after{
//     --delay: #{$i * 0.035}s;
//     --hue: #{360 / 5 * $i};
//     --left: #{$i  * 1.1}rem;
//   }
// }


@keyframes appear2 {
  0%{
    
    filter: blur(100px)
  }
  80%{
    
    filter: blur(100px)
  }
  100%{
   
    filter: blur(0)
  }
}

@keyframes appear {
  0%{
    
    opacity: 0;
  }
  90%{
    
    opacity: 0;
    
  }
  100%{
    
    opacity: 1;
   
  }
}
.banner{
  display: flex;
  // border: 1px solid black;

  cursor: pointer;
  
}





$width: 1.2vw;
// $width: 3.4vw;


  //sss
.blog-blocks,
.projects-blocks, 
.about-blocks, 
.contact-blocks{
    align-items: center;
    align-content: center;
    display: flex;
    position: relative;
    // border: solid 1px black;
    height: 10vh;
    width: $width;
    // mix-blend-mode: difference;
  
    overflow: hidden;
    filter:contrast(180%);
    animation: none;
    transform: rotate(0deg);
    transition: transform (var(--delay));
    text-shadow: 0.2vw 0.2vw 1px rgb(123, 1, 1);

    // animation-duration: 1s;
    // animation-name: rotate-animation;
    // animation-delay: var(--delay3);
    // animation-iteration-count: 1;
    // animation-fill-mode: forwards;
    
}
// $experiment: 3.3vw;
// $experiment2: 3.85vw;
// .blog-blocks, .projects-blocks {
//   width: $experiment2;
//   border: solid 1px black;
//   transition: transform (--delay);
// }

  
.banner:hover .blog-blocks,
.banner:hover .projects-blocks,
.banner:hover .about-blocks,
.banner:hover .contact-blocks{
    
    
    transform: rotatex(360deg);

    //bellow settins are in the case of using a rotate with animation; why? coz it can lead to other things - make sure you
    //switch the delay variable in the loops as well
    // animation-duration: 1s;
    // animation-name: rotate-animation;
    // animation-delay: var(--delay);
    // animation-fill-mode: forwards;
   
}
@media(max-width: 1000px){
  .Home{
    box-shadow: none;
  }
}

@media(max-width: 700px){
  // .Home {
  //   height: -webkit-fill-available;
  //   height: -moz-available;
  //   height: fill-available;
  // }

    .blog-blocks,
  .projects-blocks, 
  .about-blocks, 
  .contact-blocks{
    animation-duration: var(--animation-duration);
      animation-name: rotate-animation;
      animation-delay: var(--delay3);
      animation-iteration-count: infinite;
      animation-fill-mode: forwards;
  }
  .banner:hover .blog-blocks,
.banner:hover .projects-blocks,
.banner:hover .about-blocks,
.banner:hover .contact-blocks{
    
    
    animation: none;
   
}

}
@keyframes rotate-animation {
  0%{
    transform: rotate(0deg)
    scale(1)
  }
  
  8%{
    transform: rotate(0deg)
    scale(1);
    opacity: 1
  }
  15%{
    transform: rotateX(360deg);
    
  }
  // 10.4%{
    
  // }
  // 11%{
    
  //   opacity: 1; 
  // }
  
  // 39.9%{
  //   transform: rotate(360deg)  skew(0deg)
  // }
  // 40%{
  //   transform: rotate(360deg)  skew(-20deg)
  // }
  40.1%{
    transform: scale(1) skew(0deg)
  }
  
  44.9%{
    transform: scaleY(1.3) scaleX(1.5) skew(0deg)

  }
  45%{
    transform: scale(1) skew(-30deg)
  }
  45.1%{
    transform:   skew(0deg)
  }

  
}


  

// .banner:hover .projects-blocks::before{
//   color: hsl(var(--hue), 15%, 10%)
// }

  
.blog-blocks::before, 
.projects-blocks::before,
.about-blocks::before,
.contact-blocks::before{
    left: var(--left);
    position: absolute;
    
    font-size: 5.5vw;
    
    width: max-content;
    color: hsl(60, var(--satAndLight), var(--satAndLight));
    transition: var(--delay);
    font-family: 'Iceberg', cursive;
    animation: unset;
}

.blog-blocks::before{
  left: var(--left);
}

.blog-blocks:before{
    content: "BLOG";
}

.projects-blocks:before{
    content: "MY WORK";
   
}

.about-blocks:before{
  content: "ABOUT";
}

.contact-blocks:before{
  content: "CONTACT";
}

.banner:hover .blog-blocks::before,
.banner:hover .projects-blocks::before,
.banner:hover .about-blocks::before,
.banner:hover .contact-blocks::before {
    color: hsl(var(--hue), 15%, 10%);
    animation-duration: 1.5s;
    animation-name: twinkle;
    animation-delay: var(--delay2);
    animation-iteration-count: infinite;
}

@keyframes twinkle {
  0%{
    text-shadow: 0.2vw 0.2vw 1px rgb(196, 196, 196);
  }

  50%{
    text-shadow: -1px -1px 5px rgb(166, 10, 5), 1px -1px 5px rgb(166, 10, 5), -1px 1px 5px rgb(166, 10, 5), 1px 1px 5px rgb(166, 10, 5);
  }
  100%{
    text-shadow: 0.2vw 0.2vw 1px rgb(123, 1, 1);
  }
}


@for $i from 0 to 12 {
  .blog-blocks:nth-child(#{$i + 1}){
    --left: #{$width * $i * -1};
    --hue: #{360 /12 *$i};
    --delay: #{$i * 0.1s};
    --delay2: #{$i * 0.07}s;
    //the bellow setting is for animation, which will lead to roughly the same base look, for starters
    --delay3: #{$i * 0.01s};
    --satAndLight: #{40 /24 * $i +35%};
    --hue2: #{25 /12 * $i};
    --satAndLight2: #{50 /12 * $i+10%};
    --animation-duration: #{8s};
  }
  
}

@for $i from 0 to 20 {
  .projects-blocks:nth-child(#{$i + 1}){
    --left: #{$width * $i * -1};
    --hue: #{360 /20 *$i};
    --delay: #{$i * 0.1}s;
    --delay2: #{$i * 0.07}s;
    //the bellow setting is for animation, which will lead to roughly the same base look, for starters
    --delay3: #{$i * 0.01s};
    
    --satAndLight: #{40 /20 * $i +35}%;
    --hue2: #{25 /20 * $i};
    --satAndLight2: #{50 /20 * $i+20}%;
    --animation-duration: #{15}s
  }
}

@for $i from 0 to 14 {
  .about-blocks:nth-child(#{$i + 1}){
    --left: #{$width * $i * -1};
    --hue: #{360 /14 *$i};
    --delay: #{$i * 0.1}s;
    --delay2: #{$i * 0.07}s;
    //the bellow setting is for animation, which will lead to roughly the same base look, for starters
    --delay3: #{$i * 0.01s};
    
    --satAndLight: #{40 /14 * $i +35}%;
    --hue2: #{25 /14 * $i};
    --satAndLight2: #{50 /14 * $i+20}%;
    --animation-duration: #{20}s
  }
}

@for $i from 0 to 18 {
  .contact-blocks:nth-child(#{$i + 1}){
    --left: #{$width * $i * -1};
    --hue: #{360 /18 *$i};
    --delay: #{$i * 0.1}s;
    --delay2: #{$i * 0.07}s;
    
    //the bellow setting is for animation, which will lead to roughly the same base look, for starters
    --delay3: #{$i * 0.01s};
    
    --satAndLight: #{40 /18 * $i +35}%;
    --hue2: #{25 /18 * $i};
    --satAndLight2: #{50 /18 * $i+20}%;
    --animation-duration: #{10}s
  }
}





