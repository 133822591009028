/* .LOADING{
  font-size: 150%;
  position: fixed;
  height: 110vh;
  height: 110svh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  animation-name: appear3;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}


@keyframes appear3{
  0%{
    opacity: 1
  }
  80%{
    opacity: 1
  }
  100%{
    opacity: 0;
    
  }
}
.closed-cover{
   
    margin: 0 auto;
    height: 100vh;
    width: 100%;
    background: none;

    background: transparent;
    box-shadow: inset -51vw 0rem 0rem 0rem black, inset 51vw 0rem 0rem 0rem black;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.open-cover-1{
  pointer-events: none;
  transition: all 0.5s;
  box-shadow: inset 0 0 0rem 0 black, inset 0 0 0rem 0 black;
}

.open-cover-2{
  pointer-events:all;
  box-shadow: inset -51vw 0rem 0rem 0rem black, inset 51vw 0rem 0rem 0rem black;
} */

.LoadingCurtainExpand{
  
  width: 100%;
  height: 110vh;
  /* height: 100dvh;
  height: 100svh; */

  position: fixed;
  z-index: 100000;
  box-shadow: inset -51vw 0rem 0rem 0rem hsl(100, 17%, 3%), inset 51vw 0rem 0rem 0rem hsl(100, 17%, 3%);
  animation: expandCurtain 0.6s forwards 0.3s;
  /* animation-fill-mode: forwards; */
  pointer-events: none;
 
  
 background: transparent;
}

.LoadingCurtainExpand h2{
  color: rgb(218, 218, 218);
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  animation: loadingFadeOut 0.3s forwards;
  z-index: 1000000;
}


@keyframes loadingFadeOut {
  0%{
    opacity: 1
  }
  100%{
    opacity: 0
  }
}

@keyframes expandCurtain {
  0%{
    box-shadow: inset -51vw 0rem 0rem 0rem hsl(100, 17%, 3%), inset 51vw 0rem 0rem 0rem hsl(100, 17%, 3%);
  }
  100%{
    box-shadow: inset 0vw 0rem 0rem 0rem hsl(100, 17%, 3%), inset 0vw 0rem 0rem 0rem hsl(100, 17%, 3%);
  }
  
}










.LoadingCurtainCollapse{
  width: 100%;
  height: 110vh;
  /* height: 100dvh;
  height: 100svh; */
  position: fixed;
  z-index: 100000;
  box-shadow: inset 0vw 0rem 0rem 0rem hsl(100, 17%, 3%), inset 0vw 0rem 0rem 0rem hsl(100, 17%, 3%);
  pointer-events: none;
  animation: collapseCurtain 0.6s forwards;
  /* animation-fill-mode: forwards; */
  
  
  background: transparent;
}

@keyframes collapseCurtain {
  0%{
    box-shadow: inset 0vw 0rem 0rem 0rem hsl(100, 17%, 3%), inset 0vw 0rem 0rem 0rem hsl(100, 17%, 3%);
  }

  100%{
    box-shadow: inset -51vw 0rem 0rem 0rem hsl(100, 17%, 3%), inset 51vw 0rem 0rem 0rem hsl(100, 17%, 3%);
  }
  
}

.LoadingCurtainCollapse h2{
  color: rgb(218, 218, 218);
  opacity:0;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  animation-name: loadingFadeIn;
  animation-duration: 0.3s;
  animation-delay: 0.6s;
  animation-fill-mode: forwards;
  z-index: 1000000;
 
}


@keyframes loadingFadeIn {
  0%{
    opacity: 0
  }
  
  100%{
    opacity: 1
  }
}









.LoadingCurtainStatic{
  width: 100%;
  height: 110vh;
  position: fixed;
  z-index: 100000;
  box-shadow: inset -51vw 0rem 0rem 0rem hsl(100, 17%, 3%), inset 51vw 0rem 0rem 0rem hsl(100, 17%, 3%);
  pointer-events: none;
  
  }
  
  .LoadingCurtainStatic h2{
    color: rgb(218, 218, 218);
   
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 100000;
    
  }