

.sus{
    animation: sussy 60s infinite linear;

    // transition: all 1s;
    position: absolute;
    top:0;
    left:0;
    height: 100vh;
    width: 100%;
    z-index: -1;
}

.susCanvas{

    // transition: all 1s;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: -1;
}

@keyframes sussy{
    0%{
       filter: invert(0) saturate(65%)  hue-rotate(0deg)
    }
    5.5%{
        filter: invert(0.2) saturate(65%)  hue-rotate(180deg)
    }
    6%{
        filter: invert(0.8) saturate(65%)  hue-rotate(220deg)
    }
    50%{
        filter: invert(1) saturate(65%)  hue-rotate(360deg)
    }

    70%{
        filter: invert(0.8) saturate(65%)  hue-rotate(180deg)
    }
    70.5%{
        filter: invert(0.2) saturate(65%)  hue-rotate(140deg)
    }
    100%{
        filter: invert(0) saturate(65%)  hue-rotate(0deg)
    }

}